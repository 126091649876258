import { Component, Input, HostBinding, OnInit, OnDestroy, OnChanges, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { ICategory } from "@irl_aws/shared/lib/category";
import { ComponentCategory } from "@irl_aws/shared/lib/component-category";
import { TestMix as ITestMix } from "@irl_aws/shared/lib/testmix";
import { ILIMSReference } from "@shared/model/reference";
import { LIMSService } from "../../services/lims/lims.service";
import { ReferenceService } from "../../services/reference/reference.service";
import { Subscription } from "../../components/actions/actions";
import { DomUtil } from "../../utils/dom";
import { InfiniteListComponent } from "../../components/infinite-list/infinite-list.component";
import { InfinitePager } from "../../components/infinite-list/infinite-pager";
import { AccountState } from "../../states/account/account.state";
import { SessionState } from "../../states/session/session.state";
import { INewPanel, ISelectedPanel } from "../../states/session/session-panels";
import { HeaderComponent } from "../../components/header/header.component";
import { SearchResponse } from "../../../../../services/lib/lims/lims.service";
import { Panel } from "../../../../../../aws/shared/lib/panel";
import { SearchActions } from "./search.actions";

export enum PillTypeEnum {
    COMPONENT = 0,
    TEXT = 1
}

export interface IPill {
    type: PillTypeEnum;
    description: string;
    value: string;
    selected: boolean;
}

export interface ISearchArg {
    top: number;
    limit: number;
    texts: string[];
    components: string[];
    search: string;
    sap: string;
    sort: "qty";
    sortDir: "asc" | "desc";
}

type Category = ICategory & {
    visible?: boolean;
};
type TestMix = ITestMix;
type SearchPanel = Panel & {
    match_code?: string;
    parent_id?: number;
    price?: number;
    description?: string;
    info_includes: string;
    info_description: string;
    contains_info: boolean;
    lock?: any;
    loading?: boolean | Promise<any>;
    resolve?: Function;
    result?: any;
    matches?: Panel[];
    components?: Panel[];
    children?: SearchPanel[];
} & TestMix;

export interface ISearchResults extends SearchPanel {
    match_code?: string;
    parent_id?: number;
    price?: number;
    info: {
        description: string;
        includes: string;
    };
}

@Component({
    selector: "mad-search",
    templateUrl: "./search.component.html",
    styleUrls: [
        "./search.component.scss"
    ],
    providers: SearchActions.Providers()
})
export class SearchComponent implements OnInit, OnChanges, OnDestroy {
    @HostBinding("style") style;

    @HostBinding("class") class;

    @ViewChild(InfiniteListComponent) infiniteList: InfiniteListComponent;

    @ViewChild("header") header: HeaderComponent;

    @Output() open = new EventEmitter<any>();

    @Output() close = new EventEmitter<any>();

    @Output() addPanel = new EventEmitter<INewPanel>();

    @Input() visible = false;

    @Input() session: SessionState;

    @Input() configure: ISelectedPanel;

    @Output() configureChange = new EventEmitter<this["configure"]>();

    FULL_DIRECTORY = 0;

    TEST_MIX = 1;

    TEST_MATCH = 2;

    NONE = 0;

    DESCENDING = 1;

    ASCENDING = 2;

    isTransitioning: boolean;

    selectedTab: this["FULL_DIRECTORY"] | this["TEST_MIX"] | this["TEST_MATCH"][] = [0];

    isNoTestmix = false;

    checkedTestmix = false;

    sortDir: this["NONE"] | this["DESCENDING"] | this["ASCENDING"] = this.DESCENDING;

    isSearching: boolean;

    searchValue = "";

    searchTimer: any;

    resizeTimer: any;

    pills: IPill[] = [];

    searchResults: ISearchResults[] = [];

    matchResults: SearchPanel[] = [];

    myCategories: Category[];

    componentSelectedMap: { [key: string]: IPill } = {};

    actionSubscription: Subscription;

    onResize: (e: Event) => any;

    isResizing: boolean;

    matchPrice: number = null;

    matchCode: string = null;

    isLoadingMatch: boolean;

    pager = new InfinitePager<SearchPanel>({
        total: 20,
        getData: async (top, limit): Promise<SearchPanel[]> => {
            try {
                if (this.selectedTab[0] === this.TEST_MATCH) {
                    const results = this.matchResults;
                    if (!results) {
                        return new Array(limit);
                    }
                    return results.slice(top, top + limit);
                }
                const arg = this.getSearchArg({ top, limit });
                const lock = this.pager.lock;
                const [reference, result] = await Promise.all(
                    [
                        this.referenceService.reference(),
                        this.limsService.search(arg)
                    ]
                );

                if (lock !== this.pager.lock) {
                    return new Array(limit);
                }

                this.assignResults(arg, reference && reference.limsMap, result);

                if (!(arg.sap || (arg.search && arg.search.trim()) || (arg.components && arg.components.length) || (arg.texts && arg.texts.length))) {
                    this.pager.setTotal(0);
                    return [];
                }
                return result.data ? result.data.results as SearchPanel[] : [];
            } catch (err) {
                this.isSearching = false;
                return [] as SearchPanel[];
            }
        }
    });

    assignResults(arg: ISearchArg, reference: { [key: string]: ILIMSReference }, result: SearchResponse): void {
        const error = result.error;
        this.isSearching = false;
        if (error) {
            if (error === "NO_TESTMIX") {
                this.checkedTestmix = true;
                this.isNoTestmix = true;
                //                error = null;
                //              result.error = null;
            }
            this.searchResults = [];
            this.pager.setTotal(0);
        } else if (arg.sap) {
            this.isNoTestmix = false;
            this.checkedTestmix = true;
        }

        if (!error) {
            this.pager.setTotal(result.data.total);

            const results = result.data.results as SearchPanel[];
            const testmix = result.data.testmix;

            if (testmix) {
                for (let i = 0; i < testmix.length; i += 1) {
                    const item = results[i];
                    const testMixItem = testmix[i];

                    item.qty = testMixItem.qty;
                    item.net = testMixItem.net;
                    item.list = testMixItem.list;
                }
            }

            const pills = this.pills;
            const map = {};
            const sortMatches = ({ lims_code: a }, { lims_code: b }) => {
                const aValue = map[a];
                const bValue = map[b];
                if (aValue < bValue) {
                    return -1;
                } else if (aValue > bValue) {
                    return 1;
                }
                return 0;
            };

            for (let i = 0; i < pills.length; i += 1) {
                if (pills[i].type === PillTypeEnum.COMPONENT) {
                    map[pills[i].value] = i + 1;
                }
            }

            for (const item of results) {
                if (!item.children) {
                    item.children = [item];
                }

                const matches = [];
                const components = [];

                for (const child of item.children) {
                    const limsCode = child.lims_code;
                    const ref = reference[limsCode];

                    if (reference[limsCode]) {
                        child.info_description = ref.description;
                        if (ref.includes) {
                            if (!item.info_includes) {
                                item.info_includes = "";
                            } else {
                                item.info_includes += ", ";
                            }
                            item.info_includes += ref.includes.trim();
                        }
                        item.contains_info = true;
                    }
                    if (map[limsCode]) {
                        matches.push(child);
                    } else {
                        components.push(child);
                    }
                }

                if (matches.length) {
                    matches.sort(sortMatches);
                }

                item.matches = matches;
                item.components = components;
            }
        }

        const oldCategories = this.myCategories;
        const newCategories = result.data ? result.data.categories : [];

        this.myCategories = newCategories;

        if (oldCategories && oldCategories.length) {
            const map = {};
            for (const oldCategory of oldCategories) {
                map[oldCategory.category] = oldCategory;
            }
            for (let i = 0; i < (newCategories.length as number); i += 1) {
                if (map[newCategories[i].category]) {
                    (newCategories[i] as any).visible = oldCategories[i].visible;
                }
            }
        }
    }

    constructor(
        public limsService: LIMSService,
        public referenceService: ReferenceService,
        public action: SearchActions,
        public accountState: AccountState,
        public element: ElementRef
    ) {
    }

    ngOnInit(): void {
        window.addEventListener("resize", this.onResize = () => {
            if (this.resizeTimer) {
                clearTimeout(this.resizeTimer);
            }
            this.resizeTimer = setTimeout(() => {
                this.resizeTimer = null;
                this.isResizing = true;
                this.infiniteList.reset();
                this.ngOnChanges();
                this.isResizing = false;
            }, 300);
        });
        this.actionSubscription = this.action.subscribe((v) => {
            switch (v.type) {
                case "remove-pill":
                    if (this.pills.length && DomUtil.isCursorAtStart(v.$event.target as HTMLInputElement)) {
                        this.removeLastPill();
                    }
                    break;
                case "add-text-pill":
                    if (this.searchValue) {
                        this.addTextPill(this.searchValue.trim());
                        this.searchValue = null;
                    }
                    break;
                case "find-match":
                    if (this.matchCode) {
                        this.onMatchClick();
                    }
                    break;
                default:
                    break;
            }
        });
    }

    ngOnChanges(changes?: any) {
        this.class = this.getClass();

        if (changes) {
            if (changes.visible && this.visible !== changes.visible.previousValue) {
                this.onSearchValueChange({ delay: 0, full_reset: true, nodefer: true });
            }

            if (changes.configure) {
                this.configurePanel(this.configure);
            }
        }
    }

    ngOnDestroy(): void {
        this.actionSubscription.unsubscribe();
        window.removeEventListener("resize", this.onResize);
    }

    getClass(): string {
        return `
		${(this.visible && "visible") || "hidden"}
		${(this.selectedTab === this.FULL_DIRECTORY && "full-directory") || ""}
		${(this.selectedTab === this.TEST_MIX && "test-mix") || ""}
		${(this.selectedTab === this.TEST_MATCH && "test-match") || ""}
    `;
    }

    onOpenClick(): void {
        this.open.emit();
    }

    onPillRemoveTransitionEnd(pill: IPill, delay = 100): void {
        if (!pill.selected) {
            setTimeout(() => {
                for (let i = 0; i < this.pills.length; i += 1) {
                    if (this.pills[i] === pill && !pill.selected) {
                        if (this.pills[i].type === PillTypeEnum.COMPONENT) {
                            delete this.componentSelectedMap[this.pills[i].value];
                        }
                        this.pills.splice(i, 1);
                        return;
                    }
                }
            }, delay);
            this.onSearchValueChange();
        }
    }

    configurePanel(panel?: ISelectedPanel): void {
        if (panel) {
            this.selectedTab = [this.FULL_DIRECTORY];
            this.searchValue = "";
            this.removeAllPills();
            this.addParentPill(panel);
        }
    }

    addTextPill(value: string): void {
        this.pills.push({
            type: PillTypeEnum.TEXT,
            description: value,
            value,
            selected: true
        });
    }

    addParentPill(parent: { children?: any[]; lims_code?: string }): void {
        const children = parent.children;
        if (children && children.length) {
            for (const child of children) {
                this.addComponentPill(child);
            }
        } else {
            this.addComponentPill(parent);
        }
    }

    addComponentPill(component: { lims_code?: string; lims_name?: string; parent_category?: string; category?: string; description?: string }): void {
        const value = component.lims_code || component.category;
        const code = component.lims_code || component.parent_category || "";
        const text = component.description || component.lims_name || "";
        const description = `${code}${(code && text) && " - " || ""}${text}`;
        const found = this.componentSelectedMap[value];

        if (found) {
            if (found.selected) {
                return;
            }
            found.selected = true;
            return;
        }

        const componentPill: IPill = {
            type: PillTypeEnum.COMPONENT,
            description,
            value,
            selected: true
        };
        this.componentSelectedMap[value] = componentPill;
        this.pills.push(componentPill);

        this.onSearchValueChange();
    }

    removeAllPills(): void {
        const pills = this.pills;
        const map = this.componentSelectedMap;
        for (const pill of pills) {
            pill.selected = false;
            delete map[pill.value];
        }
    }

    removePill(pill: IPill): void {
        if (pill) {
            pill.selected = false;
        }
        this.checkConfigurePills();
    }

    removeLastPill(): void {
        const pills = this.pills;
        for (let i = pills.length - 1; i >= 0; i -= 1) {
            if (pills[i] && pills[i].selected) {
                pills[i].selected = false;
                this.checkConfigurePills();
                return;
            }
        }
    }

    checkConfigurePills(): void {
        if (this.configure) {
            let found = false;
            for (const pill of this.pills) {
                if (pill.selected) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                console.error("unsetting configure");
                this.configure = null;
                this.configureChange.emit(null);
            }
        }
    }

    // find gaps
    // get each gap (start-10,end+10)

    public toggleCategory(category: Category | ComponentCategory): void {
        const key = this.getKey(category);
        const found = this.componentSelectedMap[key];

        if (!category.active) {
            return;
        }

        if (found && found.selected) {
            // this.removePill(found);
        } else {
            this.addComponentPill(category);
        }
    }

    private getKey(category: Category | ComponentCategory): string {
        return (category as ComponentCategory).lims_code || (category as Category).category;
    }

    public isSelected(category: Category | ComponentCategory): boolean {
        const key = this.getKey(category);
        return this.componentSelectedMap[key]?.selected;
    }

    onTabClick(): void {
        if (!this.visible) {
            this.open.emit();
            return;
        }

        // reset the total
        this.onSearchValueChange({ delay: 0, full_reset: true, nodefer: true });
    }

    public containsCode(limsCode: string): boolean {
        return this.session.panels.sessionContainsPanel(limsCode);
    }

    async onResultAddClick({ lims, lims_code, match_code, lims_name, material, children }: ISearchResults, resultEl: HTMLElement): Promise<void> {
        if (this.session.panels.sessionContainsPanel(lims_code)) {
            console.warn("Already present!");
            return;
        }

        if (this.configure) {
            var { id: parent_id } = this.configure;
        }

        let matchPrice: number = null;

        if (match_code) {
            if (this.matchPrice !== null && this.matchPrice !== undefined) {
                matchPrice = this.matchPrice;
            }
        }

        // TODO disable modify (all features) for codes that are added but dont officially exist yet
        this.addPanel.emit({
            lims,
            lims_code,
            lims_name,
            material,
            match_code,
            match_price: matchPrice,
            children,
            parent_id
        });
    }

    changeSortDirection(): void {
        if (this.sortDir === this.ASCENDING) {
            this.sortDir = this.NONE;
        } else if (this.sortDir === this.NONE) {
            this.sortDir = this.DESCENDING;
        } else {
            this.sortDir = this.ASCENDING;
        }
        this.onSearchValueChange();
    }

    trackByCategory(i: number, data: Category["children"][0]): any {
        return (data as { lims_code: string }).lims_code || data.category;
    }

    async onSearchValueChange(arg: { delay?: number; full_reset?: boolean; noanimate?: boolean; nodefer?: boolean; } = { delay: 200, full_reset: false, noanimate: false, nodefer: false }): Promise<void> {
        const delay = typeof arg.delay === "number" ? arg.delay : 200;
        const noanimate = (arg.noanimate === undefined || arg.noanimate === null) ? false : arg.noanimate;
        const fn = async (): Promise<void> => {
            this.searchTimer = null;

            try {
                if (!this.pager.total.value || arg.full_reset) {
                    this.pager.total.next(40);
                }
                this.pager.reset(!arg.full_reset);
                if (this.selectedTab[0] === this.TEST_MATCH) {
                    if (!this.matchCode) {
                        this.matchResults = [];
                    }
                    this.pager.total.next((this.matchResults && this.matchResults.length) || 0);
                }
                if (this.infiniteList) {
                    this.infiniteList.reset();
                    this.infiniteList.scrollToTop();
                }
            } catch (e) {
                console.error(e);
            }
        };

        if (this.searchTimer) {
            clearTimeout(this.searchTimer);
        }

        if (!noanimate) {
            this.isSearching = true;
        }

        if (delay) {
            this.searchTimer = setTimeout(fn, delay);
        } else {
            fn();
        }
    }

    getSearchArg({ top, limit }): ISearchArg {
        const texts = [];
        const components = [];
        const pills = this.pills;
        const search = this.searchValue;
        const sap = this.selectedTab[0] === this.TEST_MIX ? this.session.sapId : undefined;
        let sort: "qty";
        let sortDir: "asc" | "desc";

        if (sap && this.sortDir !== this.NONE) {
            sort = "qty";
            sortDir = this.sortDir === this.ASCENDING ? "asc" : "desc";
        }

        for (const pill of pills) {
            if (pill.selected) {
                switch (pill.type) {
                    case PillTypeEnum.TEXT:
                        texts.push(pill.value);
                        break;
                    case PillTypeEnum.COMPONENT:
                        components.push(pill.value);
                        break;
                    default:
                        break;
                }
            }
        }

        return {
            top,
            limit,
            texts,
            components,
            search,
            sap,
            sort,
            sortDir
        };
    }

    async onMatchClick(): Promise<void> {
        this.isLoadingMatch = true;
        try {
            const results = (await this.limsService.match({ matchCode: this.matchCode })) as any as SearchPanel[];
            this.matchResults = results;
            for (const result of results) {
                result.matches = result.children;
            }
            this.onSearchValueChange({ delay: 0, noanimate: true });
        } catch (e) {
            console.error(e);
        }
        setTimeout(() => {
            this.isLoadingMatch = false;
        }, 1000);
    }

    getMaxInfoHeight(): string {
        return Math.min(this.infiniteList.element.nativeElement.offsetHeight - 60, 500) + "px";
    }

    isInfoFullScreen(infoTarget: any): boolean {
        if (!infoTarget) {
            return false;
        }
        let space = document.body.offsetWidth;
        infoTarget = infoTarget.element || infoTarget;
        infoTarget = infoTarget.nativeElement || infoTarget;

        if (infoTarget.getBoundingClientRect) {
            space -= infoTarget.getBoundingClientRect().right;
        }
        if (space < 1024) {
            return true;
        }
        return false;
    }

    getInfoWidth(infoTarget: any): string {
        if (infoTarget) {
            if (infoTarget.getBoundingClientRect) {
                const width = document.body.offsetWidth;
                return (width - 60) + "px";
            }
            return "1024px";
        }
        return "";
    }
}
